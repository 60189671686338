import { AxiosResponse } from "axios";
import { MediaStatusType } from "../constants/MediaConstants";
import { SurveyConstants } from "../constants/SurveyConstants";
import { MedicalFilesFiltersType } from "../containers/Prospects";
import { AccountMergeType, DuplicateAccountDTO } from "../types/AccountManagement";
import { Announcement, ClubAlert } from "../types/Alerts";
import { AmsFormDTO, AmsFormResultDTO } from "../types/Ams";
import { ConsentToReselect } from "../types/ConsentToReselect";
import { DataUploadResponse } from "../types/DataUpload";
import { EbisAmateurReviewProspect, EbisProspectMatch, MatchReviewActionsPayload } from "../types/EbisAmateurReview";
import { InvitationDetailsDTO, InvitationFileResponse, InviteByEmail } from "../types/EmailInvitations";
import {
  EventDTO,
  EventDataOptionDTO,
  EventDownloadEntryTypeDTO,
  EventEntryTypeDTO,
  EventLandingSelected,
  EventProspectAllResults,
  EventProspectResponseDTO,
  EventProspectSearchRequestDTO,
  EventSearchRequestDTO,
  EventSearchResponseDTO,
  ProspectEventProfilePreviewDTO,
  ProspectEventResultDTO,
  SaveEventDTO,
} from "../types/Events";
import {
  InvitationEmailValidationDTO,
  ManageDuplicateInvitationsRequestDTO,
  ProspectInvitationDuplicateDTO,
  ProspectPrimaryInvitationDTO,
} from "../types/InvitationManagement";
import { LocaleDTO } from "../types/Language";
import { MediaUpdateDTO } from "../types/Media";
import {
  MedicalGridChartResponse,
  MedicalGridFilters,
  MedicalGridResponse,
  MedicalSelected,
  ProspectMedicalFileReviewDTO,
} from "../types/Medical";
import {
  AppInformationDTO,
  Country,
  DraftYears,
  GameParticipationDTO,
  InvitedBy,
  Positions,
  PregameActivityDTO,
  RsvpStatusDTO,
  SchoolTypeData,
  StateProvince,
  States,
  SystemSetting,
  SystemSettingDTO,
  TagResponse,
} from "../types/Metadata";
import { OrganizationDTO, OrganizationManagementDTO } from "../types/Organization";
import {
  BoxFolderConfigDTO,
  ClubTopProspectSubmissionRequest,
  CreateTagFromTagsDTO,
  EligibilityCommentDTO,
  EligibilityGridResponse,
  EligibilityGridSearchDTO,
  EligibilityProspectDetailsDTO,
  EligibilitySelected,
  OrganizationTagDTO,
  ProspectBasicDTO,
  ProspectContactsDTO,
  ProspectFamilyInfoDTO,
  ProspectGridChartResponse,
  ProspectGridResponse,
  ProspectListFilters,
  ProspectMediaInfo,
  ProspectMedicalDTO,
  ProspectMedicalInfoRequest,
  ProspectMedicalRequestModalDTO,
  ProspectMedicalSubmissionDTO,
  ProspectMedicalSubmissionsTabDTO,
  ProspectMedicalTabDTO,
  ProspectPreviewDTO,
  ProspectRelativeSportLevel,
  ProspectRelativeSportType,
  ProspectRelativeType,
  ProspectSchoolsDTO,
  ProspectSelected,
  ProspectSharedLink,
  ProspectTagDTO,
  ProspectTaskDTO,
  TopProspectGridRequest,
  TopProspectGridResponse,
  TopProspectRoundDTO,
  TopProspectSelected,
} from "../types/Prospects";
import { QuartzJobSummaryDTO } from "../types/Quartz";
import { ResourceDTO, ResourceManagementDTO, ResourceWithFileDTO, TagDTO } from "../types/ResourceManagement";
import {
  AppUserEbisTeamMapDTO,
  ClubGameDTO,
  ClubProspectGameParticipationDTO,
  FeaturedScheduleResponse,
  GameChangeDTO,
  GameDTO,
  GameNoteDTO,
  OrgScheduleMasterFiltersType,
  OrgUserTeamMapDTO,
  PostalAddress,
  ProspectGameParticipationDTO,
  RankDTO,
  RoleAssignmentDTO,
  RoleDTO,
  RoleRankMappingDTO,
  RsvpDTO,
  SaveScheduleRankRoleDTO,
  SaveSharedGameDTO,
  ScheduleGroupVisibilityTypeDTO,
  ScheduleProspectSubscriptionRequestDTO,
  ScheduleRankRoleDTO,
  ScheduleRankRoleSearchRequestDTO,
  ScheduleTeamProfileDTO,
  ScheduleTeamProfileProspectsDTO,
  ScheduleTeamProfileScheduleDTO,
  ScheduleTeamProfileScheduleFiltersDTO,
  ScheduleTeamSearchRequestDTO,
  ScheduleTeamSearchResponseDTO,
  ScheduleUserManagementRequestDTO,
  ScheduleUserManagementResponseDTO,
  ScheduleUserProfileDTO,
  TeamAssignmentDTO,
  UpcomingGamesCalendarResponse,
  UpcomingGamesFiltersType,
  UserScheduleDateRangeRequestDTO,
  UserTeamsDTO,
  VideoCoordinatorAttendanceDTO,
  VideoCoordinatorAttendanceDateRangeRequestDTO,
  WeatherIcon,
} from "../types/Schedule";
import { School } from "../types/School";
import { CreateStoryDTO, Story, StoryType } from "../types/Stories";
import {
  LeagueTeamDTO,
  SaveStudyEventDTO,
  StudyDTO,
  StudyEventDTO,
  StudyEventSearchResponseDTO,
  StudyLandingSelected,
  StudyProspectResponseDTO,
  StudyProspectSearchRequestDTO,
  StudyProspectStatusHistoryDTO,
  StudyProspectStatusSaveDTO,
  StudySearchRequestDTO,
  StudySearchResponseDTO,
  StudyStatusDTO,
} from "../types/Study";
import {
  CopySurveyDTO,
  SurveyAndResultDTO,
  SurveyDTO,
  SurveyFilters,
  SurveyStatus,
  TaskTypeDTO,
} from "../types/Surveys";
import { OtherTaskDTO } from "../types/Tasks";
import { EditCustomTemplateDTO, TemplateType, ViewTemplateDTO } from "../types/Templates";
import { TopProspectsValidationResponse } from "../types/TopProspects";
import { GCSUploadDTO } from "../types/Uploads";
import { OrgUserDTO, UserDetails } from "../types/UserTypes";
import { ValueDisplay } from "../types/ValueDisplay";
import { VideoLogFilterRequest, VideoLogFilterResponse, VideoLogReportProspect } from "../types/VideoLog";
import { BocWaiverDTO, CopyWaiverDTO, WaiverFilters, WaiverGridFilters, WaiverGridResponse } from "../types/Waivers";
import AuthClient from "./AuthClient";

const BASE_URL: string = window.MLBBest.envVariables.BASE_URL as string;

export const getUser = async (): Promise<UserDetails> => (await AuthClient.get(`/user/details`)).data;

export const getDraftYears = async (): Promise<DraftYears> => (await AuthClient.get(`/unsecured/draftYears`)).data;

export const getCountries = async (searchString?: string): Promise<Country[]> => {
  const searchQuery = !!searchString ? `?searchString=${searchString}` : "";
  return (await AuthClient.get(`/unsecured/country/list${searchQuery}`)).data;
};

export const getStateProvinces = async (countryId: number, searchString?: string): Promise<StateProvince[]> => {
  const searchQuery = !!searchString ? `&searchString=${searchString}` : "";
  return (await AuthClient.get(`/unsecured/state-province/list?countryId=${countryId}${searchQuery}`)).data;
};

export const getStates = async (): Promise<States> => (await AuthClient.get(`/mlb/filter/state`)).data;

export const getPositions = async (): Promise<Positions> => (await AuthClient.get(`/mlb/filter/position`)).data;

export const getInvitedBy = async (): Promise<InvitedBy[]> => (await AuthClient.get(`/mlb/filter/invited-by`)).data;

export const getTags = async (): Promise<TagResponse[]> => (await AuthClient.get(`/mlb/filter/tag`)).data;

export const getSchools = async (schoolStateCode?: string): Promise<School[]> => {
  const searchQuery = !!schoolStateCode ? `?stateProvince=${schoolStateCode}` : "";
  return (await AuthClient.get(`unsecured/school/list${searchQuery}`)).data;
};

export const getSchoolTypes = async (): Promise<SchoolTypeData[]> =>
  (await AuthClient.get(`/prospects/metadata/schoolTypes`)).data;

/* Prospect Grid */
export const getProspectGridList = async (filter: ProspectListFilters): Promise<ProspectGridResponse> =>
  (await AuthClient.post("/clubBoc/prospectGrid", filter)).data;

export const getProspectGridCharts = async (filter: ProspectListFilters): Promise<ProspectGridChartResponse> =>
  (await AuthClient.post("/clubBoc/prospectGrid/charts", filter)).data;

export const getProspectGridHasClubQuestionnaire = async (): Promise<boolean> =>
  (await AuthClient.get("/clubBoc/prospectGrid/hasClubQuestionnaire")).data;

export const getProspectGridProspectPreview = async (prospectId: number): Promise<ProspectPreviewDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}`)).data;

export const getProspectMedicalBoxConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medical/config`)).data;

export const canViewProspectMedical = async (prospectId: number): Promise<any> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medical/viewable`)).data;

export const getProspectMediaBoxConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/media/config`)).data;

export const getProspectFormUploadBoxConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/formUpload/config`)).data;

export const getProspectMedicalSubmissionsUploadBoxConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medicalSubmissions/config`)).data;

export const getProspectMedicalTabInfo = async (prospectId: number): Promise<ProspectMedicalTabDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medical`)).data;

export const getProspectMedicalSubmissions = async (prospectId: number): Promise<ProspectMedicalSubmissionsTabDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medicalSubmissions`)).data;

export const saveProspectMedicalSubmissions = async (
  prospectId: number,
  submissions: ProspectMedicalSubmissionDTO[],
): Promise<ProspectMedicalSubmissionsTabDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/profile/prospects/${prospectId}/medicalSubmissions`, submissions)).data;

export const getProspectMedicalSubmissionsBoxConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/medicalSubmissions/config`)).data;

export const getProspectMediaInfo = async (prospectId: number): Promise<ProspectMediaInfo> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/media`)).data;

export const getProspectMediaFileURL = async (prospectId: number, fileId: string): Promise<string> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/media/${fileId}/download`)).data;

export const getProspectSchoolHistory = async (prospectId: number): Promise<ProspectSchoolsDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/schools`)).data;

export const getProspectTasks = async (prospectId: number): Promise<ProspectTaskDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/tasks`)).data;

export const getProspectVisibleOrgs = async (prospectId: number): Promise<OrganizationDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/visibleOrgs`)).data;

export const getProspectTaskPDF = async (prospectId: number, taskId: number | string) =>
  await AuthClient.get(`/prospect/pdf/task/${taskId}/${prospectId}`, { responseType: "blob" });

export const getProspectTaskUrl = (prospectId: number, taskId: string | number) => {
  return `${BASE_URL}/prospect/pdf/task/${taskId}/${prospectId}`;
};

export const getProspectTaskVideo = async (prospectId: number, taskId: number | string) =>
  await AuthClient.get(`/prospects/videoUpload/${prospectId}/download/${taskId}/`, { responseType: "blob" });

export const getProspectContacts = async (prospectId: number): Promise<ProspectContactsDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/prospects/${prospectId}/contacts`)).data;

export const getProspectFamilyInfo = async (prospectId: number): Promise<ProspectFamilyInfoDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/profile/${prospectId}/familyInfo`)).data;

/* Prospect Grid: Actions */

export const reassignDraftYear = async (selected: ProspectSelected, newDraftYear: number) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/reassignDraftYear`, {
    selected,
    newDraftYear: newDraftYear,
  });

export const deleteInvitations = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/deleteInvitations`, selected);

export const inviteProspectsFromGrid = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/invite`, selected);

export const subscribeToProspectsFromGrid = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/subscribe`, selected);

export const archiveProspects = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/archive`, selected);

export const unarchiveProspects = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/unarchive`, selected);

export const markDraftAndFollow = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/markDraftAndFollow`, selected);

export const clearDraftAndFollow = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/clearDraftAndFollow`, selected);

export const liftDraftAndFollow = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/liftDraftAndFollow`, selected);

export const applyDraftAndFollow = async (selected: ProspectSelected) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/applyDraftAndFollow`, selected);

export const getInvitationDetails = async (invitationId: number): Promise<InvitationDetailsDTO> =>
  (await AuthClient.get(`/clubBoc/invitations/details/${invitationId}`)).data;

export const checkEmailExistsForResend = async (invitationId: number, email: string): Promise<boolean> =>
  (
    await AuthClient.post(`/clubBoc/invitations/checkEmailExistsForResend`, {
      invitationId: invitationId,
      email: email,
    })
  ).data;

export const resendInvitation = async (invitationId: number, email: string) =>
  await AuthClient.post(`/clubBoc/invitations/resend`, {
    invitationId: invitationId,
    email: email,
  });

export const sendInviteToBocInvite = async (invitationId: number) =>
  await AuthClient.post(`/clubBoc/invitations/sendInviteToBocInvite/${invitationId}`);

export const validateBulkInvitation = async (file: File): Promise<InvitationFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return (await AuthClient.post(`/clubBoc/bulkInvitations/validate`, formData, config)).data;
};

export const getProspectTags = async (): Promise<OrganizationTagDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospectGrid/actions/tags`)).data;

export const getTagDTOsByOrg = async (): Promise<TagDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospectGrid/actions/tags`)).data;

export const associateTags = async (selected: ProspectSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/associateTags`, {
    selected,
    tagIds: tagIds,
  });

export const dissociateTags = async (selected: ProspectSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/dissociateTags`, {
    selected,
    tagIds: tagIds,
  });

export const getOrgRanks = async (): Promise<RankDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospectGrid/actions/ranks`)).data;

export const getOrgRoleRankMappings = async (): Promise<RoleRankMappingDTO[]> =>
  (await AuthClient.get(`/scheduleRankRole/getOrgRoleRankMappings`)).data;

export const associateRanks = async (selected: ProspectSelected, rankIds: number[]) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/associateRanks`, {
    selected,
    rankIds,
  });

export const dissociateRanks = async (selected: ProspectSelected, rankIds: number[]) =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/dissociateRanks`, {
    selected,
    rankIds,
  });

export const getStudyTeams = async (): Promise<OrganizationDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospectGrid/actions/studyTeams`)).data;

export const assignTeam = async (prospectId: number, teamId: number): Promise<AxiosResponse<string>> =>
  await AuthClient.post(`/clubBoc/prospectGrid/actions/assignTeam`, {
    prospectId,
    teamId,
  });

export const unassignTeam = async (prospectId: number): Promise<AxiosResponse<string>> =>
  await AuthClient.delete(`/clubBoc/prospectGrid/actions/unassignTeam/${prospectId}`);

export const getProspectMedicalRequests = async (prospectId: number): Promise<ProspectMedicalRequestModalDTO> =>
  (await AuthClient.get(`/clubBoc/prospectGrid/actions/getProspectMedicalRequestModalInfo/${prospectId}`)).data;

export const submitMedicalRequests = async (request: ProspectMedicalInfoRequest) =>
  await AuthClient.post("clubBoc/prospectGrid/actions/createMedicalRequests", request);

export const exportProspectGrid = async (selected: ProspectSelected) =>
  await AuthClient.post("clubBoc/prospectGrid/actions/export", selected, { responseType: "blob" });

export const exportPendingProfiles = async (selected: ProspectSelected) =>
  await AuthClient.post("clubBoc/prospectGrid/actions/exportPendingProfiles", selected, { responseType: "blob" });

/* Medical Grid */
export const getMedicalGrid = async (medicalGridFilters: MedicalGridFilters): Promise<MedicalGridResponse> =>
  (await AuthClient.post(`/clubBoc/medicalGrid`, medicalGridFilters)).data;

export const getMedicalGridCharts = async (medicalGridFilters: MedicalGridFilters): Promise<MedicalGridChartResponse> =>
  (await AuthClient.post(`/clubBoc/medicalGrid/charts`, medicalGridFilters)).data;

export const exportMedicalGrid = async (selected: MedicalSelected) =>
  await AuthClient.post("clubBoc/medicalGrid/actions/export", selected, { responseType: "blob" });

export const associateTagsFromMedicalGrid = async (selected: MedicalSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/medicalGrid/actions/associateTags`, {
    selected,
    tagIds: tagIds,
  });

export const dissociateTagsFromMedicalGrid = async (selected: MedicalSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/medicalGrid/actions/dissociateTags`, {
    selected,
    tagIds: tagIds,
  });

/* Duplicate Invitations */
export const getPotentialDuplicateInvitations = async (): Promise<ProspectPrimaryInvitationDTO[]> =>
  (await AuthClient.get(`/clubBoc/invitation/management/prospect/duplicates`)).data;

export const getMatchingInvites = async (prospectId: number): Promise<ProspectInvitationDuplicateDTO[]> =>
  (await AuthClient.get(`/clubBoc/invitation/management/prospect/${prospectId}/duplicates`)).data;

export const processDuplicateInvitations = async (requestDTO: ManageDuplicateInvitationsRequestDTO): Promise<any> =>
  (await AuthClient.post(`/clubBoc/invitation/management/prospect/duplicates`, requestDTO)).data;

export const getTemplate = async (templateName: TemplateType): Promise<ViewTemplateDTO> =>
  (await AuthClient.get(`/clubBoc/template/${templateName}`)).data;

export const getProcessedClubTemplate = async (templateName: TemplateType): Promise<ViewTemplateDTO | null> =>
  (await AuthClient.get(`/clubBoc/template/club/custom/${templateName}`)).data;

export const getRawClubTemplate = async (templateName: TemplateType): Promise<EditCustomTemplateDTO | undefined> =>
  (await AuthClient.get(`/template/custom/${templateName}`)).data;

export const saveClubCustomText = async (template: EditCustomTemplateDTO): Promise<any> =>
  (await AuthClient.post(`/template/custom`, template)).data;

export const postInviteByEmail = async (inviteByEmail: InviteByEmail): Promise<any> =>
  (await AuthClient.post(`/clubBoc/invitations/byEmail`, inviteByEmail)).data;

export const validateInvitationEmail = async (email: string): Promise<InvitationEmailValidationDTO> =>
  (await AuthClient.post(`/clubBoc/invitations/email/validate`, { email: email })).data;

/* Duplicate Accounts */
export const getDuplicateCount = async (): Promise<number> =>
  (await AuthClient.get(`/clubBoc/account/management/duplicates/count`)).data;

export const getDuplicateAccounts = async (): Promise<DuplicateAccountDTO[]> =>
  (await AuthClient.get(`/clubBoc/account/management/duplicates`)).data;

export const getMatchingAccounts = async (prospectId: number): Promise<DuplicateAccountDTO[]> =>
  (await AuthClient.get(`/clubBoc/account/management/${prospectId}/matches`)).data;

export const mergeAccounts = async (accountMergeTypes: AccountMergeType[]): Promise<any> =>
  (await AuthClient.post(`/clubBoc/account/management/merge`, accountMergeTypes)).data;

export const deleteMergedAccounts = async (mergedAccountIds: number[]): Promise<any> =>
  (await AuthClient.post(`/clubBoc/account/management/merge/delete`, mergedAccountIds)).data;

/* EBIS Amateur Reviews */
export const getEbisAmateurReviewProspects = async (): Promise<EbisAmateurReviewProspect[]> =>
  (await AuthClient.get(`/clubBoc/ebisAmateurReview/prospects`)).data;

export const getEbisProspectMatches = async (prospectId: number): Promise<EbisProspectMatch[]> =>
  (await AuthClient.get(`/clubBoc/ebisAmateurReview/prospects/${prospectId}/matches`)).data;

export const submitReviewActions = async (matchReviewActionsPayload: MatchReviewActionsPayload): Promise<any> =>
  (await AuthClient.post(`/clubBoc/ebisAmateurReview/submitReviewActions`, matchReviewActionsPayload)).data;

/* Media Reviews */
export const getProspectMediaReviews = async (): Promise<ProspectBasicDTO[]> =>
  (await AuthClient.get(`/clubBoc/media`)).data;

export const getProspectMediaFiles = async (prospectId: number): Promise<MediaUpdateDTO[]> =>
  (await AuthClient.get(`/clubBoc/media/prospect/${prospectId}`)).data;

export const getProspectMediaFilesWithStatus = async (
  prospectId: number,
  mediaStatusId: MediaStatusType,
): Promise<MediaUpdateDTO[]> =>
  (await AuthClient.get(`/clubBoc/media/prospect/${prospectId}/status/${mediaStatusId}`)).data;

export const saveProspectMediaFiles = async (
  prospectId: number,
  mediaUpdateDTOs: MediaUpdateDTO[],
): Promise<MediaUpdateDTO[]> => (await AuthClient.post(`/clubBoc/media/prospect/${prospectId}`, mediaUpdateDTOs)).data;

/* Medical File Reviews */
export const getProspectMedicalReviews = async (
  searchTerm: string,
  filters: MedicalFilesFiltersType,
): Promise<ProspectBasicDTO[]> => {
  const request = {
    prospectName: searchTerm,
    invitedBy: !!filters.invitedBy ? filters.invitedBy.value : undefined,
    documentStatus: !!filters.documentStatus ? filters.documentStatus.value : undefined,
    // @ts-ignore
    tagIds: filters.tags.map((tag: ValueDisplay) => tag.value.tagId),
    draftYears: filters.draftYears.map((val: ValueDisplay) => val.value!.toString()),
  };
  return (await AuthClient.post(`/clubBoc/medicalReviews`, request)).data;
};

export const getAllProspectMedicalFiles = async (prospectId: number): Promise<ProspectMedicalFileReviewDTO> =>
  (await AuthClient.get(`/clubBoc/medicalReviews/${prospectId}/files`)).data;

export const getAllProspectMedicalRequests = async (prospectId: number): Promise<ProspectMedicalDTO[]> =>
  (await AuthClient.get(`/clubBoc/medicalReviews/${prospectId}/requests`)).data;

export const updateProspectMedicalRequests = async (
  prospectId: number,
  requests: ProspectMedicalDTO[],
): Promise<ProspectMedicalDTO[]> =>
  (await AuthClient.post(`/clubBoc/medicalReviews/${prospectId}/requests`, requests)).data;

export const deleteProspectMedicalRequestComment = async (
  prospectId: number,
  requestId: number,
  noteId: number,
): Promise<any> =>
  (await AuthClient.delete(`/clubBoc/medicalReviews/${prospectId}/requests/${requestId}/notes/${noteId}`)).data;

export const createProspectMedicalRequestComment = async (
  prospectId: number,
  requestId: number,
  comment: string,
): Promise<any> =>
  (
    await AuthClient.post(`/clubBoc/medicalReviews/${prospectId}/requests/${requestId}/notes`, comment, {
      headers: { "Content-Type": "text/plain" },
    })
  ).data;

export const markProspectFilesReviewed = async (prospectId: number): Promise<ProspectBasicDTO[]> => {
  return (await AuthClient.post(`/clubBoc/medicalReviews/${prospectId}/markReviewed`)).data;
};

export const updateMedicalFile = async (file: ProspectSharedLink): Promise<ProspectSharedLink> =>
  (await AuthClient.put(`/clubBoc/medicalReviews`, file)).data;

/* Study Management */
export const searchStudies = async (search: StudySearchRequestDTO): Promise<StudySearchResponseDTO> =>
  (await AuthClient.post(`/study/search`, search)).data;

export const searchStudyEvents = async (search: EventSearchRequestDTO): Promise<StudyEventSearchResponseDTO> =>
  (await AuthClient.post(`/study/searchStudyEvents`, search)).data;

export const getStudyEventEntryTypes = async (studyEventId: number | string): Promise<EventEntryTypeDTO[]> =>
  (await AuthClient.get(`/study/event/${studyEventId}/entryTypes`)).data;

export const getAllStudyProspects = async (search: StudyProspectSearchRequestDTO): Promise<EventProspectAllResults> =>
  (await AuthClient.post(`/study/search/all/participants`, search)).data;

export const getStudyProspectStatusHistory = async (
  studyId: number,
  prospectId: number,
): Promise<StudyProspectStatusHistoryDTO[]> =>
  (await AuthClient.get(`/study/${studyId}/prospectStatusHistory/${prospectId}`)).data;

export const saveStudyStatus = async (dto: StudyProspectStatusSaveDTO): Promise<void> =>
  (await AuthClient.post(`/study/statusUpdate`, dto)).data;

export const getStudies = async (): Promise<StudyDTO[]> => (await AuthClient.get(`/study`)).data;

export const getEventsByStudy = async (studyId: number | string): Promise<EventDTO[]> =>
  (await AuthClient.get(`/study/${studyId}/events`)).data;

export const getDownloadStudyEntryTypes = async (studyId: number): Promise<EventDownloadEntryTypeDTO[]> =>
  (await AuthClient.get(`/study/${studyId}/downloadEntryTypes`)).data;

export const downloadStudyFiles = async (datasourceType: string, entryTypeId: string, studyId: number) =>
  await AuthClient.get(`/study/download/source/${datasourceType}/entryTypeId/${entryTypeId}/study/${studyId}`, {
    responseType: "blob",
  });

export const downloadMultipleStudyFiles = async (studyId: number, eventTypes: string) =>
  await AuthClient.get(`/study/download/source/multi/study/${studyId}?eventTypes=${eventTypes}`, {
    responseType: "blob",
  });

export const getStudyProspectsForTag = async (
  search: StudyProspectSearchRequestDTO,
): Promise<StudyProspectResponseDTO> => (await AuthClient.post(`/study/search/participants`, search)).data;

export const saveStudy = async (saveStudyDTO: SaveStudyEventDTO): Promise<StudyEventDTO> =>
  (await AuthClient.post(`/study/save`, saveStudyDTO)).data;

export const associateTagsFromStudyLanding = async (selected: StudyLandingSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/studyLandingPage/actions/associateTags`, {
    selected,
    tagIds: tagIds,
  });

export const dissociateTagsFromStudyLanding = async (selected: StudyLandingSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/studyLandingPage/actions/dissociateTags`, {
    selected,
    tagIds: tagIds,
  });

export const publishStudyData = async (studyId: number): Promise<void> =>
  (await AuthClient.put(`/study/publishData/${studyId}`)).data;

/* Event Management */
export const getEventTagOptions = async (): Promise<TagDTO[]> =>
  (await AuthClient.get(`/eventManagement/tagOptions`)).data;

export const searchEvents = async (search: EventSearchRequestDTO): Promise<EventSearchResponseDTO> =>
  (await AuthClient.post(`/eventManagement/search`, search)).data;

export const getEventProspectsForTag = async (
  search: EventProspectSearchRequestDTO,
): Promise<EventProspectResponseDTO> => (await AuthClient.post(`/eventManagement/search/participants`, search)).data;

export const getAllEventProspects = async (search: EventProspectSearchRequestDTO): Promise<EventProspectAllResults> =>
  (await AuthClient.post(`/eventManagement/search/all/participants`, search)).data;

export const saveEvent = async (saveEventDTO: SaveEventDTO): Promise<EventDTO> =>
  (await AuthClient.post(`/eventManagement/save`, saveEventDTO)).data;

export const getEventEntryTypes = async (eventId: number | string): Promise<EventEntryTypeDTO[]> =>
  (await AuthClient.get(`/eventManagement/event/${eventId}/entryTypes`)).data;

export const getDownloadEventEntryTypes = async (eventId: number): Promise<EventDownloadEntryTypeDTO[]> =>
  (await AuthClient.get(`/eventManagement/event/${eventId}/downloadEntryTypes`)).data;

export const getEvents = async (): Promise<EventDTO[]> => (await AuthClient.get(`/eventManagement/events`)).data;

export const getProspectNonStudyEvents = async (prospectId: number): Promise<ProspectEventResultDTO[]> =>
  (await AuthClient.get(`/eventManagement/prospect/${prospectId}/events`)).data;

export const getProspectStudyEvents = async (prospectId: number): Promise<ProspectEventResultDTO[]> =>
  (await AuthClient.get(`/study/prospect/${prospectId}/events/study`)).data;

export const processEventUpload = async (
  entryTypeId: string,
  eventId: string,
  prospectId: string | undefined,
  dto: GCSUploadDTO,
): Promise<void> => {
  const queryStr = !!prospectId ? `?prospectId=${prospectId}` : ``;
  const url = `/event/file/upload/entryTypeId/${entryTypeId}/eventId/${eventId}${queryStr}`;
  await AuthClient.post(url, dto);
};

export const getSignedUploadUrl = async (eventId: string, dto: GCSUploadDTO): Promise<GCSUploadDTO> =>
  (await AuthClient.post(`/event/file/upload/${eventId}/getGCSUrl`, dto)).data;

export const downloadEventFiles = async (datasourceType: string, entryTypeId: string, eventId: number) =>
  await AuthClient.get(
    `/eventManagement/download/source/${datasourceType}/entryTypeId/${entryTypeId}/eventId/${eventId}`,
    {
      responseType: "blob",
    },
  );

export const downloadMultipleEventFiles = async (eventId: number, eventTypes: string) =>
  await AuthClient.get(`/eventManagement/download/source/multi/eventId/${eventId}?eventTypes=${eventTypes}`, {
    responseType: "blob",
  });

export const associateTagsFromEventLanding = async (selected: EventLandingSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/eventLandingPage/actions/associateTags`, {
    selected,
    tagIds: tagIds,
  });

export const dissociateTagsFromEventLanding = async (selected: EventLandingSelected, tagIds: number[]) =>
  await AuthClient.post(`/clubBoc/eventLandingPage/actions/dissociateTags`, {
    selected,
    tagIds: tagIds,
  });

export const getEventDataOptions = async (): Promise<EventDataOptionDTO[]> =>
  (await AuthClient.get(`/eventManagement/eventDataOptions`)).data;

export const getEnabledEventDataOptions = async (eventId: number): Promise<EventDataOptionDTO[]> =>
  (await AuthClient.get(`/eventManagement/eventDataOptions/${eventId}/enabled`)).data;

export const setEventDataOptions = async (eventId: number, dtos: EventDataOptionDTO[]): Promise<void> =>
  (await AuthClient.post(`/eventManagement/eventDataOptions/${eventId}/set`, dtos)).data;

export const publishEventData = async (eventId: number): Promise<void> =>
  (await AuthClient.put(`/eventManagement/publishData/${eventId}`)).data;

export const getEventProfileProspectPreview = async (prospectId: number): Promise<ProspectEventProfilePreviewDTO> =>
  (await AuthClient.get(`/eventManagement/profile/prospect/${prospectId}`)).data;

export const generateSportsvisionPdf = async (eventId: number): Promise<void> =>
  (await AuthClient.put(`/eventManagement/generateSportsVisionPDF/${eventId}`)).data;

/* Organization Management */
export const getAllOrganizations = async (): Promise<OrganizationManagementDTO[]> =>
  (await AuthClient.get(`/organizationManagement/all`)).data;

export const saveOrganizations = async (
  organizationManagementDTOs: OrganizationManagementDTO[],
): Promise<OrganizationManagementDTO[]> =>
  (await AuthClient.post(`/organizationManagement/save`, organizationManagementDTOs)).data;

/* System Settings */
export const getSystemSetting = async (id: string): Promise<SystemSetting> =>
  (await AuthClient.get(`/systemSettings/${id}`)).data;

export const getAllSystemSettings = async (): Promise<SystemSettingDTO[]> =>
  (await AuthClient.get(`/systemSettings/all`)).data;

export const saveSystemSetting = async (systemSettings: SystemSettingDTO[]): Promise<SystemSettingDTO[]> =>
  (await AuthClient.post(`/systemSettings/save`, systemSettings)).data;

export const getClubAppInformation = async (): Promise<AppInformationDTO> =>
  (await AuthClient.get(`/systemSettings/unsecured/clubAppInformation`)).data;

/* Quartz Job Management */
export const getQuartzJobs = async (): Promise<QuartzJobSummaryDTO[]> => (await AuthClient.get(`/quartz`)).data;

export const updateQuartzJobStatus = async (quartzJobSummaryDTO: QuartzJobSummaryDTO): Promise<QuartzJobSummaryDTO[]> =>
  (await AuthClient.put(`/quartz/status`, quartzJobSummaryDTO)).data;

export const runQuartzJob = async (quartzJobSummaryDTO: QuartzJobSummaryDTO): Promise<QuartzJobSummaryDTO[]> =>
  (await AuthClient.put(`/quartz/run`, quartzJobSummaryDTO)).data;

/* Resource Management */
export const getResourceManagementData = async (): Promise<ResourceManagementDTO> =>
  (await AuthClient.get(`/clubBoc/resourceManagement/resources`)).data;

export const updateResourceTags = async (resourceId: number, tags: TagDTO[]) =>
  await AuthClient.post(`/clubBoc/resourceManagement/updateResourceTags/${resourceId}`, tags);

export const createUpdateResource = async (resourceWithFile: ResourceWithFileDTO): Promise<ResourceDTO> =>
  (await AuthClient.post(`/clubBoc/resourceManagement/createUpdateResource`, resourceWithFile)).data;

export const deleteResource = async (resourceId: number): Promise<boolean> =>
  (await AuthClient.delete(`/clubBoc/resourceManagement/deleteResource/${resourceId}`)).data;

/** Act as Prospect **/
export const actAsProspect = async (prospectId: number, pathname: string): Promise<States> =>
  (await AuthClient.post(`/mlb/prospect/${prospectId}/bocActAsProspect`, { referrer: pathname })).data;

/** Task Management **/
export const regenerateProspectTaskPdf = async (prospectId: number, taskId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/task/regenerate/prospect/${prospectId}/task/${taskId}`)).data;

export const reopenProspectTask = async (prospectId: number, taskId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/task/reopen/prospect/${prospectId}/task/${taskId}`)).data;

export const removeProspectTask = async (prospectId: number, taskId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/task/remove/prospect/${prospectId}/task/${taskId}`)).data;

export const switchProspectTask = async (prospectId: number, taskId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/task/switch/prospect/${prospectId}/task/${taskId}`)).data;

/** Top Prospects **/
export const getTop50Active = async (): Promise<boolean> =>
  (await AuthClient.get(`/clubBoc/topProspects/getTop50Active`)).data;

export const getTop300Active = async (): Promise<boolean> =>
  (await AuthClient.get(`/clubBoc/topProspects/getTop300Active`)).data;

export const validateTopProspects = async (file: File, roundId: number): Promise<TopProspectsValidationResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  // FIXME: temporary fix to send up category in query string
  //formData.append("category", category);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return (await AuthClient.post(`/clubBoc/topProspects/submitTopProspects?roundId=${roundId}`, formData, config)).data;
};

export const fetchTopProspectVotingRounds = async (): Promise<TopProspectRoundDTO[]> =>
  (await AuthClient.get(`/clubBoc/topProspects/voting/rounds`)).data;

export const downloadTopProspectTemplate = async () =>
  await AuthClient.get("/clubBoc/topProspects/voting/template", { responseType: "blob" });

export const getSubmittedClubsForVotingRound = async (votingRoundId: number): Promise<OrganizationDTO[]> =>
  (await AuthClient.get(`/clubBoc/topProspects/submittedOrgs/${votingRoundId}`)).data;

export const fetchNotVotedClubs = async (votingRoundId: number): Promise<string[]> =>
  (await AuthClient.get(`/clubBoc/topProspects/notVoted/${votingRoundId}`)).data;

export const getTopProspects = async (request: TopProspectGridRequest): Promise<TopProspectGridResponse> =>
  (await AuthClient.post(`/clubBoc/topProspects/getTopProspects`, request)).data;

export const associateTopProspects = async (request: TopProspectSelected) =>
  await AuthClient.post(`/clubBoc/topProspects/associateTag`, request);

export const dissociateTopProspects = async (request: TopProspectSelected) =>
  await AuthClient.post(`/clubBoc/topProspects/dissociateTag`, request);

export const exportTopProspects = async (request: TopProspectGridRequest) =>
  await AuthClient.post("clubBoc/topProspects/export", request, { responseType: "blob" });

export const getVotingClubs = async (): Promise<OrganizationDTO[]> =>
  (await AuthClient.get(`/clubBoc/topProspects/votingClubs`)).data;

export const getClubTopProspectSubmissions = async (request: ClubTopProspectSubmissionRequest) =>
  (await AuthClient.post(`/clubBoc/topProspects/getClubTopProspectSubmissions`, request)).data;

export const downloadTopProspectTemplateFromOrgIdAndRoundId = async (orgId: number, roundId: number) =>
  await AuthClient.get(`/clubBoc/topProspects/voting/${roundId}/template/${orgId}`, { responseType: "blob" });

/* Waiver Grid */
export const getWaiverGrid = async (request: WaiverGridFilters): Promise<WaiverGridResponse> =>
  (await AuthClient.post("clubBoc/waiver/grid", request)).data;

export const getProspectMedicalCoverSheet = async (prospectId: number, notes: string, selectedFileIds: number[]) => {
  const body = {
    prospectId: prospectId,
    notes: notes,
    selectedFileIds: selectedFileIds,
  };
  return await AuthClient.post(`clubBoc/medicalReviews/prospect/${prospectId}/coversheet`, body, {
    responseType: "blob",
  });
};

export const getAllTags = async (): Promise<ProspectTagDTO[]> => (await AuthClient.get("clubBoc/tags")).data;

export const getAllConsents = async (): Promise<ConsentToReselect[]> =>
  (await AuthClient.get("clubBoc/consentToReselect/consents")).data;

export const createTagFromTags = async (tag: CreateTagFromTagsDTO): Promise<ProspectTagDTO> =>
  (await AuthClient.post("clubBoc/tags/createTagFromTags", tag)).data;

export const saveTag = async (tag: ProspectTagDTO): Promise<ProspectTagDTO> =>
  (await AuthClient.post("clubBoc/tags", tag)).data;

export const deleteTags = async (tags: ProspectTagDTO[]): Promise<any> =>
  (await AuthClient.put("clubBoc/tags/delete", tags)).data;

export const archiveTags = async (tags: ProspectTagDTO[]): Promise<any> =>
  (await AuthClient.put("clubBoc/tags/archive", tags)).data;

export const activateTags = async (tags: ProspectTagDTO[]): Promise<any> =>
  (await AuthClient.put("clubBoc/tags/activate", tags)).data;

export const getVideoLogList = async (filter: VideoLogFilterRequest): Promise<VideoLogFilterResponse> =>
  (await AuthClient.post("mlb/videoLog/prospects/videoList", filter)).data;

export const generateVideoLogCSV = async (prospects: VideoLogReportProspect[]) =>
  await AuthClient.post("mlb/videoLog/prospects/generateCSV", prospects, {
    responseType: "blob",
  });

export const getAnnouncements = async (): Promise<Announcement[]> =>
  (await AuthClient.get("prospects/announcements")).data;

export const getAllAnnouncements = async (): Promise<Announcement[]> =>
  (await AuthClient.get("prospects/announcements/all")).data;

export const expireAnnouncement = async (announcementId: number): Promise<any> =>
  (await AuthClient.put(`prospects/announcements/expire/${announcementId}`)).data;

export const updateAnnouncement = async (announcement: Announcement): Promise<Announcement> =>
  (await AuthClient.put("prospects/announcements", announcement)).data;

export const createAnnouncement = async (announcement: Announcement): Promise<Announcement> =>
  (await AuthClient.post("prospects/announcements", announcement)).data;

export const getAllSurveys = async (surveyFilters: SurveyFilters): Promise<SurveyDTO[]> =>
  (await AuthClient.post("survey/all", surveyFilters)).data;

export const getSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.get(`survey/${surveyId}`)).data;

export const createSurvey = async (surveyDTO: SurveyDTO): Promise<SurveyDTO> =>
  (await AuthClient.post(`survey`, surveyDTO)).data;

export const copySurvey = async (copySurveyDTO: CopySurveyDTO): Promise<SurveyDTO> =>
  (await AuthClient.post(`survey/copy`, copySurveyDTO)).data;

export const updateSurvey = async (surveyDTO: SurveyDTO): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyDTO.id}`, surveyDTO)).data;

export const validateSurvey = async (surveyDTO: SurveyDTO): Promise<any> =>
  (await AuthClient.post(`survey/validate`, surveyDTO)).data;

export const activateSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.ACTIVE}`)).data;

export const deactivateSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.DEACTIVATED}`)).data;

export const inReviewSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.REVIEW_IN_PROGRESS}`)).data;

export const finalizeSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.FINALIZED}`)).data;

export const pendingClubFinalizationSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.PENDING_CLUB_FINALIZATION}`)).data;

export const draftModeSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/${surveyId}/status/update/${SurveyConstants.DRAFT_MODE}`)).data;

export const activateWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.ACTIVE}`)).data;

export const deactivateWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.DEACTIVATED}`)).data;

export const inReviewWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.REVIEW_IN_PROGRESS}`)).data;

export const finalizeWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.FINALIZED}`)).data;

export const pendingClubFinalizationWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.PENDING_CLUB_FINALIZATION}`)).data;

export const draftModeWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/status/update/${SurveyConstants.DRAFT_MODE}`)).data;

export const getProspectSurveyPDF = async (surveyId: number) =>
  await AuthClient.get(`/prospect/pdf/survey/${surveyId}`, { responseType: "blob" });

export const getProspectWaiverPDF = async (waiverId: number) =>
  await AuthClient.get(`/prospect/pdf/waiver/${waiverId}`, { responseType: "blob" });

export const getSurveyStatuses = async (): Promise<SurveyStatus[]> => (await AuthClient.get(`survey/statuses`)).data;

export const getTaskTypesFilterOptions = async (): Promise<TaskTypeDTO[]> =>
  (await AuthClient.get(`survey/taskTypes`)).data;

export const getProspectSurvey = async (surveyId: number, prospectId: number): Promise<SurveyAndResultDTO> =>
  (await AuthClient.get(`/prospects/survey/${surveyId}/prospect/${prospectId}`)).data;

export const getWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.get(`clubBoc/waiver/${waiverId}`)).data;

export const translateWaiver = async (waiverId: number | string): Promise<BocWaiverDTO> =>
  (await AuthClient.put(`clubBoc/waiver/${waiverId}/translate`)).data;

export const createWaiver = async (waiver: BocWaiverDTO): Promise<BocWaiverDTO> =>
  (await AuthClient.post("clubBoc/waiver", waiver)).data;

export const getAllWaivers = async (waiverFilters: WaiverFilters): Promise<BocWaiverDTO[]> =>
  (await AuthClient.post("clubBoc/waiver/all", waiverFilters)).data;

export const copyWaiver = async (copyWaiverDTO: CopyWaiverDTO): Promise<BocWaiverDTO> =>
  (await AuthClient.post(`clubBoc/waiver/copy`, copyWaiverDTO)).data;

export const translateSurvey = async (surveyId: number | string): Promise<SurveyDTO> =>
  (await AuthClient.put(`survey/translate/${surveyId}`)).data;

export const getLocales = async (): Promise<LocaleDTO[]> => (await AuthClient.get(`prospects/metadata/locales`)).data;

export const getStoryTypes = async (): Promise<StoryType[]> => (await AuthClient.get(`clubBoc/story/types`)).data;

export const getStories = async (): Promise<Story[]> => (await AuthClient.get(`clubBoc/story`)).data;

export const updateStory = async (story: Story): Promise<Story> =>
  (await AuthClient.put(`clubBoc/story/${story.storyId}`, story)).data;

export const createStory = async (story: CreateStoryDTO): Promise<Story> =>
  (await AuthClient.post(`clubBoc/story`, story)).data;

export const getRelativeTypes = async (): Promise<ProspectRelativeType[]> =>
  (await AuthClient.get(`prospects/metadata/relativeTypes`)).data;

export const getSportTypes = async (): Promise<ProspectRelativeSportType[]> =>
  (await AuthClient.get(`prospects/metadata/sportTypes`)).data;

export const getSportLevels = async (): Promise<ProspectRelativeSportLevel[]> =>
  (await AuthClient.get(`prospects/metadata/sportLevels`)).data;

export const getPregameActivities = async (): Promise<PregameActivityDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/pregame-activities`)).data;
};

export const getGameParticipationLookup = async (): Promise<GameParticipationDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/game-participation`)).data;
};

export const getGameCancellationReasons = async (): Promise<GameParticipationDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/game-cancellation-reasons`)).data;
};

export const getRsvpStatusOptions = async (): Promise<RsvpStatusDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/rsvp-status`)).data;
};

export const getStudyStatusOptions = async (): Promise<StudyStatusDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/study-status`)).data;
};

export const getScheduleWeatherIcons = async (): Promise<WeatherIcon[]> => {
  return (await AuthClient.get(`/unsecured/metadata/weather/icons`)).data;
};

export const getLeaguesAndTeams = async (studyId: number): Promise<LeagueTeamDTO> =>
  (await AuthClient.get(`/study/${studyId}/canViewLeaguesTeamsList`)).data;

export const eligibilityGridSearch = async (filters: EligibilityGridSearchDTO): Promise<EligibilityGridResponse> =>
  (await AuthClient.post(`clubBoc/eligibility/search`, filters)).data;

export const exportEligibilityGrid = async (selected: EligibilitySelected) =>
  await AuthClient.post("clubBoc/eligibility/export", selected, { responseType: "blob" });

export const assignBulkEligibilityStatus = async (selected: EligibilitySelected, statusId: number): Promise<any> =>
  await AuthClient.post(`clubBoc/eligibility/updateStatus/bulk`, {
    selected,
    statusId,
  });

export const updateProspectEligibilityStatus = async (prospectId: number, statusId: number): Promise<any> =>
  (await AuthClient.post(`clubBoc/eligibility/updateStatus/prospect/${prospectId}/status/${statusId}`)).data;

export const getProspectEligibilityDetails = async (prospectId: number): Promise<EligibilityProspectDetailsDTO> =>
  (await AuthClient.get(`clubBoc/eligibility/prospect/${prospectId}`)).data;

export const getProspectEligibilityComment = async (prospectId: number): Promise<EligibilityCommentDTO> =>
  (await AuthClient.get(`clubBoc/eligibility/comment/${prospectId}`)).data;

export const saveProspectEligibilityComment = async (
  prospectId: number,
  commentDTO: EligibilityCommentDTO,
): Promise<EligibilityCommentDTO> =>
  (await AuthClient.post(`clubBoc/eligibility/comment/${prospectId}`, commentDTO)).data;

export const removeProspectEligibilityComment = async (
  prospectId: number,
  commentDTO: EligibilityCommentDTO,
): Promise<EligibilityCommentDTO> =>
  (await AuthClient.delete(`clubBoc/eligibility/comment/${prospectId}/${commentDTO.id}`)).data;

export const validateDataUpload = async (file: File, overrideValidation: boolean): Promise<DataUploadResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return (
    await AuthClient.post(`/clubBoc/dataUpload/validate?overrideValidation=${overrideValidation}`, formData, config)
  ).data;
};

/* AMS */
export const getAmsForms = async (): Promise<AmsFormDTO[]> => (await AuthClient.get(`ams/forms`)).data;

export const saveFormResponse = async (eventId: number, formResponse: AmsFormResultDTO): Promise<any> =>
  (await AuthClient.post(`ams/${eventId}/responses/saveFormResponse`, formResponse)).data;

export const getProspectEventAmsFormResponses = async (
  eventId: number,
  prospectId: number,
): Promise<AmsFormResultDTO[]> => (await AuthClient.get(`ams/${eventId}/responses/${prospectId}`)).data;

/* Schedule Ranks and Roles*/
export const searchScheduleRankRoles = async (
  search: ScheduleRankRoleSearchRequestDTO,
): Promise<ScheduleRankRoleDTO[]> => (await AuthClient.post(`/scheduleRankRole/search`, search)).data;

export const getVisibilityTypes = async (): Promise<ScheduleGroupVisibilityTypeDTO[]> =>
  (await AuthClient.get(`/scheduleRankRole/visibilityTypes`)).data;

export const saveRankRole = async (saveDTO: SaveScheduleRankRoleDTO): Promise<any> => {
  (await AuthClient.post(`/scheduleRankRole/saveRankRole`, saveDTO)).data;
};

/* Schedule Teams */
export const searchScheduleTeams = async (
  search: ScheduleTeamSearchRequestDTO,
): Promise<ScheduleTeamSearchResponseDTO> => (await AuthClient.post(`/schedule/team/search`, search)).data;

export const assignTeams = async (dtos: TeamAssignmentDTO): Promise<void> =>
  await AuthClient.post(`/schedule/team/assign`, dtos);

export const unassignTeams = async (dtos: TeamAssignmentDTO): Promise<void> =>
  await AuthClient.post(`/schedule/team/unassign`, dtos);

export const getTeamProfile = async (teamId: number): Promise<ScheduleTeamProfileDTO> =>
  (await AuthClient.get(`/schedule/team/${teamId}`)).data;

export const getTeamProfileProspects = async (teamId: number): Promise<ScheduleTeamProfileProspectsDTO> =>
  (await AuthClient.get(`/schedule/team/${teamId}/prospects`)).data;

export const getTeamProfileSchedule = async (
  teamId: number,
  filters: ScheduleTeamProfileScheduleFiltersDTO,
): Promise<ScheduleTeamProfileScheduleDTO> =>
  (await AuthClient.post(`/schedule/team/${teamId}/schedule`, filters)).data;

export const getTeamProfileScheduleYears = async (teamId: number): Promise<number[]> =>
  (await AuthClient.get(`/schedule/team/${teamId}/schedule/years`)).data;

export const getTeamAssignmentUserOptions = async (): Promise<OrgUserDTO[]> =>
  (await AuthClient.get(`/schedule/team/userOptions`)).data;

export const getUserAssignedTeams = async (): Promise<AppUserEbisTeamMapDTO[]> =>
  (await AuthClient.get(`/schedule/team/userAssignedTeams`)).data;

export const getUserTeams = async (): Promise<UserTeamsDTO> => (await AuthClient.get(`/schedule/team/userTeams`)).data;

export const getOrgUserTeams = async (): Promise<OrgUserTeamMapDTO[]> =>
  (await AuthClient.get(`/schedule/team/userOrgUserTeams`)).data;

/* Schedule Subscriptions */
export const editScheduleProspectSubscription = async (dto: ScheduleProspectSubscriptionRequestDTO): Promise<void> =>
  (await AuthClient.post(`/schedule/subscription/editSubscription`, dto)).data;

/* Schedule User Profile */
export const getScheduleUserProfile = async (userId: number): Promise<ScheduleUserProfileDTO> =>
  (await AuthClient.get(`/schedule/user/${userId}`)).data;

export const getScheduleUserProfileTeams = async (userId: number): Promise<UserTeamsDTO> =>
  (await AuthClient.get(`/schedule/user/${userId}/teams`)).data;

/* Schedule */

export const fetchUserManagementData = async (
  search: ScheduleUserManagementRequestDTO,
): Promise<ScheduleUserManagementResponseDTO> =>
  (await AuthClient.post(`/scheduleRankRole/userManagement`, search)).data;

export const getOrgRoles = async (): Promise<RoleDTO[]> => (await AuthClient.get(`/scheduleRankRole/getRoles`)).data;

export const associateRoles = async (dtos: RoleAssignmentDTO): Promise<void> =>
  await AuthClient.post(`/scheduleRankRole/assignRoles`, dtos);
export const dissociateRoles = async (dtos: RoleAssignmentDTO): Promise<void> =>
  await AuthClient.post(`/scheduleRankRole/unAssignRoles`, dtos);

export const getUserSchedule = async (filters: UpcomingGamesFiltersType): Promise<UpcomingGamesCalendarResponse> =>
  (await AuthClient.post(`/schedule/userSchedule`, filters)).data;

export const getUserScheduleByDateRange = async (request: UserScheduleDateRangeRequestDTO): Promise<ClubGameDTO[]> =>
  (await AuthClient.post(`/schedule/userScheduleDateRange`, request)).data;

export const getGameParticipation = async (
  gameId: number,
  teamId: number,
): Promise<ClubProspectGameParticipationDTO[]> =>
  (await AuthClient.get(`/schedule/game/${gameId}/team/${teamId}`)).data;

export const editGame = async (dto: GameDTO): Promise<ClubGameDTO> =>
  (await AuthClient.post(`/schedule/editGame`, dto)).data;

export const saveNewGame = async (dto: GameDTO): Promise<ClubGameDTO> =>
  (await AuthClient.post(`/schedule/saveNewGame`, dto)).data;

export const editRsvp = async (rsvp: RsvpDTO): Promise<void> =>
  (await AuthClient.post(`/schedule/editRsvp`, rsvp)).data;

export const getGameHistory = async (gameId: number): Promise<GameChangeDTO[]> =>
  (await AuthClient.get(`/schedule/game/${gameId}/history`)).data;

export const getGameNotes = async (gameId: number): Promise<GameNoteDTO[]> =>
  (await AuthClient.get(`/schedule/game/${gameId}/notes`)).data;

export const saveGameNote = async (gameId: number, noteDTO: GameNoteDTO): Promise<void> => {
  return await AuthClient.post(`/schedule/game/${gameId}/addNote`, noteDTO);
};

export const deleteGameNote = async (noteId: number): Promise<void> => {
  return await AuthClient.delete(`/schedule/deleteNote/${noteId}`);
};

export const saveParticipationEdits = async (gameId: number, dtos: ProspectGameParticipationDTO[]): Promise<void> => {
  return await AuthClient.post(`/schedule/game/${gameId}/updateProspectGameRoster`, dtos);
};

export const getProspectAggregateGameInfo = async (request: OrgScheduleMasterFiltersType): Promise<ClubGameDTO[]> => {
  return (await AuthClient.post(`/schedule/game/aggregateSchedule`, request)).data;
};

export const getVisibleScheduleUsers = async (): Promise<OrgUserDTO[]> =>
  (await AuthClient.get(`/scheduleRankRole/canViewList`)).data;

export const getVisibleRanks = async (): Promise<RankDTO[]> =>
  (await AuthClient.get(`/scheduleRankRole/canViewRankList`)).data;

export const getGame = async (gameId: number): Promise<ClubGameDTO> =>
  (await AuthClient.get(`/schedule/game/${gameId}`)).data;

export const shareGame = async (dto: SaveSharedGameDTO): Promise<void> =>
  await AuthClient.post(`/schedule/game/share`, dto);

export const getFeaturedSchedule = async (filters: UpcomingGamesFiltersType): Promise<FeaturedScheduleResponse> =>
  (await AuthClient.post(`/schedule/featuredSchedule`, filters)).data;

export const getPostalFromZipcode = async (zipcode: string): Promise<PostalAddress> =>
  (await AuthClient.get(`/clubBoc/zipcode/${zipcode}`)).data;

/* Alerts */
export const getDplAlerts = async (): Promise<ClubAlert[]> => (await AuthClient.get(`/clubBoc/alerts`)).data;

export const readAlerts = async (): Promise<ClubAlert[]> => (await AuthClient.put(`/clubBoc/alerts/read`)).data;

export const dismissAlert = async (alertId: number): Promise<ClubAlert[]> =>
  (await AuthClient.put(`/clubBoc/alerts/${alertId}/dismiss`)).data;

/* Clips */
export const getClipsToken = async (): Promise<string> => (await AuthClient.get(`/clips/token`)).data;

export const getOtherTasks = async (): Promise<OtherTaskDTO[]> => (await AuthClient.get(`/clubBoc/otherTasks`)).data;

/* Attendance */
export const saveVideoCoordinatorAttendance = async (attendance: VideoCoordinatorAttendanceDTO) =>
  await AuthClient.post(`/schedule/attendance/save`, attendance);

export const getVideoCoordinatorAttendance = async (
  request: VideoCoordinatorAttendanceDateRangeRequestDTO,
): Promise<VideoCoordinatorAttendanceDTO[]> => (await AuthClient.post(`/schedule/attendance/user`, request)).data;

export const getClubVideoCoordinatorAttendance = async (
  request: VideoCoordinatorAttendanceDateRangeRequestDTO,
): Promise<VideoCoordinatorAttendanceDTO[]> => (await AuthClient.post(`/schedule/attendance/club`, request)).data;
